import React from "react";
import Banner from "../../../components/Landers/Banner";
import Brands from "../../../components/Landers/BrandsTemp";
import Footer from "../../../components/Landers/Footer";
import GetStarted from "../../../components/Landers/GetStarted";
import Header from "../../../components/Landers/Header";
import styles from "../../../components/Landers/landers.module.scss";
import SectionTemplate from "../../../components/Landers/SectionTemplate";
import SEO from "../../../components/Landers/SEO";
import Testimonials from "../../../components/Landers/Testimonials";
import ThreeSteps from "../../../components/Landers/ThreeSteps";
import WhatsUnique from "../../../components/Landers/WhatsUnique";
import InfoCreditCheck from "../../../components/Landers/InfoCreditCheck";
import DataInfo from "../../../components/Data";

const steps = [
  {
    h5: "Initiate Passport Verification",
    p:
      "Get the information present on the first and last pages of the passport.",
  },
  {
    h5: "Validate information using the Machine Readable Zone (MRZ) algorithm",
    p:
      "The MRZ on the passport is cross-checked against the MRZ generated using algorithms and the format is verified by scanning against the Global Reference Database.",
  },
  {
    h5: "Get real-time status",
    p:
      "A passport is verified valid only if all the characters match during the MRZ test. Any inconsistency in the process indicates the manipulation of personal information.",
  },
];

const testimonials = [
  {
    name: "Mariam M ",
    company: "Touchkin eServices Pvt. Ltd.",
    p: `It's the first time we are doing background checks. But still, SpringVerify gave us very good clarity on the process. 
    They helped us in setting up the account quickly and and helped us perform checks without any hassle.`,
  },
  {
    name: "Maryam J ",
    company: "Resolvit Management and Technology Consulting India Pvt. Ltd.",
    p: `SpringVerify is very simple, reliable, and straightforward. 
    The UX is awesome, checks are fast and most importantly all my queries are answered promptly without me having to wait for long.`,
  },
  {
    name: "Rupali M ",
    company: "Integrichain India Pvt. Ltd.",
    p: `The best part about SpringVerify is its user portal. It provides real-time updates making our 
    HR job easier. The entire process is so seamless saving us a lot of time, effort and resources in the process.`,
  },
];

export default () => (
  <div className={styles.PassportWrap}>
    <SEO
      title="Instant Passport Verification Service| SpringVerify India"
      desc="Validate and verify your candidate's authenticity by running a thorough passport verification with SpringVerify's instant screening solutions."
      url="https://in.springverify.com/screenings/passport-verification/"
      imgurl="https://assets-sv-in.gumlet.io/test-pages/SV-IN+(reference+check).jpg"
    />
    <div className={styles.topSection}>
      <Header />
      <Banner
        h1={
          "Verify the Information \non your Candidate's \nPassport Effortlessly"
        }
        p="Run a thorough passport verification to validate if the information provided by your candidate is accurate and authentic."
        img={
          "https://assets-sv-in.gumlet.io/LP/passport-verification/banner.svg"
        }
      />
    </div>
    <Brands data={DataInfo.CRBrandsTemp} />
    <section>
      <SectionTemplate
        img="passport-verification/need-passport.svg"
        h2="Need for Passport Checks"
        p={`Passport verification helps you verify the authenticity of a candidate’s supplied passport. This way you can ensure:`}
        ul={[
          "The individual is claiming the right identity and nationality",
          "No potential legal ramifications to your business",
          "No alterations made to personal details",
          "The MRZ code is not compromised",
        ]}
      />
    </section>
    <InfoCreditCheck data={DataInfo.InfoPassPort} />
    <ThreeSteps
      subHeader={"\n"}
      h2={"How SpringVerify helps you run \nPassport Verification?"}
      steps={steps}
      candidate="https://assets-sv-in.gumlet.io/LP/passport-verification/passport-verification.png"
      path="passport-verification/"
    />
    <WhatsUnique
      path="passport-verification"
      info={[
        "Easy onboarding",
        "Safe and secure",
        "Transparent pricing",
        "Workflow automation",
        "Tailored solutions based on your requirements",
        "Powered by AI and Blockchain",
        "Remote-friendly and contactless verifications",
        "Compliant with \nISO 27001:9000, PCI, and more",
      ]}
    />
    <Testimonials testimonials={testimonials} path="passport-verification" />
    <GetStarted
      img="passport-verification/ppGetStarted.svg"
      h3={"Ready to get started \nwith us?"}
      p="Confirm your candidate's authenticity by running a thorough passport verification in an instant"
    />
    <Footer />
  </div>
);
